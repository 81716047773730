
    .header-wraper {
        background: #101010;
        background-size: cover;
        background-position: center;
        color: white;
        position: relative;
        height: 40vh;
    }

    .Typewriter__wrapper {
        color: white !important;
    }

    .main-info {
        display: flex;
        position: absolute;
        top: 170px;
        left: 0;
        right: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .main-info h1 {
        text-transform: uppercase;
    }


    .homepage-form-layout form {
        width: 380px;
        max-width: 100%;
        position: relative;
        margin-top: 1rem;
    }

    .homepage-form-layout form input:first-child {
        display: inline-block;
        width: 100%;
        padding: 14px 130px 14px 15px;
        border: 2px solid #f9000c;
        outline: none;
        border-radius: 30px;
        background-color: white;
    }

    .homepage-form-layout form input:last-child {
        position: absolute;
        display: inline-block;
        outline: none;
        border: none;
        padding: 10px 30px;
        border-radius: 30px;
        background-color: #f9000c;
        color: white;
        box-shadow: 0px 0px 5px #000, 0px 0px 15px #858585;
        top: 6px;
        right: 6px;
    }


@media (max-width: 430px) {
    .header-wraper {
        background-color: #191919;
        color: white;
        position: relative;
        height: 26vh;
    }

    .main-info {
        display: flex;
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .main-info h1 {
        text-transform: uppercase;
    }


    .homepage-form-layout form {
        width: 200px;
        max-width: 100%;
        position: relative;
        margin-top: 1rem;
    }

    .homepage-form-layout form input:first-child {
        width: 100%;
        padding: 14px 40px 15px;
        border: 2px solid #f9000c;
        outline: none;
        border-radius: 30px;
        background-color: white;
        
    }

    .homepage-form-layout form input:last-child {
        outline: none;
        width: 200px;
        max-width: 100%;
        position: relative;
        margin-top: 1rem;
        border: none;
        padding: 14px 50px;
        border-radius: 30px;
        background-color: #f9000c;
        color: white;
        box-shadow: 0px 0px 10px #000, 0px 0px 15px #858585;
        margin-left: 8px;
    }
}

@media (max-width: 260px) {
    .header-wraper {
        background-color: #191919;
        color: white;
        position: relative;
        height: 30vh;
    }
}
