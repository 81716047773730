.experience {
  padding: 0.7rem;
  background-color: #191919;
  height: 100%;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: white;
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #f9000c;
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid #f9000c;
  background: white;
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: white;
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: white;
  font-weight: 900;
}

.resume-btn {
  outline: none;
  border: none;
  padding: 10px 100px;
  border-radius: 30px;
  background-color: #f9000c;
  color: white;
  box-shadow: 0px 0px 5px #000, 0px 0px 5px #858585;
  border: 1px solid #f9000c;
  font-weight: 700;
  margin-bottom: 30px;
  transition: .4s;
}


.experience .resume-btn:hover {
  background-color: transparent;
  border: 1px solid #f9000c;
  padding: 10px 100px;
}

.btn-section {
  margin-bottom: 2rem;
  margin-top: 2.5rem;
}

@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875;
  }

  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}
