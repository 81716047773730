.title {
    color: white;
    text-transform: uppercase;
}

.box{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

.card {
    height: 500px;
    width: 550px;
    background: #191919 !important;
    border-radius: 20px;
    margin-bottom: 8rem;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.card i{
    font-size: 50px;
    display: block;
    text-align: center;
    margin: 25px 0px;
    color: #f9000c;
}

h5 {
    color: white;
    font-size: 23px;
    margin: 2rem 0 !important;
}

.description p{
    color: white;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 25px;
}

.card .button {
    background-color: #f9000c;
    color: white;
    text-decoration: none;
    border: 2px solid transparent;
    font-weight: bold;
    padding: 9px 22px;
    border-radius: 30px;
    transition: .4s;
    box-shadow: 0px 0px 5px #000, 0px 0px 5px #858585;
}


.card .button:hover{
    background-color: transparent;
    border: 2px solid #f9000c;
    cursor: pointer;
}

@media (max-width: 991px) {
    .card {
        height: 600px;
        width: 550px;
        background: #191919;
        border-radius: 20px;
        margin-bottom: 3rem;
        position: relative;
        overflow: hidden;
        text-align: center;
    }
}

@media (min-width: 280px) {
    .card {
        height: 100%;
        width: 100%;
        background: #191919;
        border-radius: 20px;
        margin-bottom: 3rem;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .project-image {
        width: 100%;
        height: 100%;
    }
}