span {
  color: #f9000c;
}

.header-space {
  padding-left: 0.25rem;
  font-weight: 550;
}

.nav-logo {
  color: white !important;
  font-size: 2.25rem;
  text-transform: uppercase;
}

.nav-logo:hover {
  color: white;
}

.navbar {
  background: #101010 !important;
}

/*---Nav Links---*/
.link {
  font-weight: 500;
  font-size: medium;
  letter-spacing: 0.0375rem;
  cursor: pointer;
  color: white !important;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 1rem;
  background: transparent;
}

.link:hover {
  color: #f9000c !important;
  transition: 0.4s !important;
}

.active {
  color: #f9000c !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(249,0,12, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
