.contacts {
  background-color: #191919;
  padding: 3rem 0;
  color: yellow !important;
  }

  .contacts h1 {
    color: white;
    padding: 1.3rem 0;
    text-transform: uppercase;
  }

  .contacts p {
    color: white;
    font-size: 1.2rem;
  }

  
  .form-control::placeholder {
    color: white !important;
    opacity: 1;
}

.form-control:-ms-input-placeholder { 
  color: white !important;
}

.form-control::-ms-input-placeholder { 
  color: white !important;
}

  /*------------------ INPUTS -------------------------------*/
  .contacts input, .contacts textarea {
    width: 100%;
    box-sizing: border-box;
    background: none;
    resize: none;
    border: 0;
    border-bottom: 0.125rem solid #f9000c;
    border-radius: 0;
    color: white;
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 1rem;
    padding-left: 0 !important;
  }
  
  .contacts .container {
    padding: 4rem 0;
  }
  
  .contacts input:focus, .contacts textarea:focus {
    background: transparent;
    color: white;
    border-bottom: 0.125rem solid white;
  }
  
  .contacts textarea {
    height: 11.75rem !important;
  }
  
  .contact-btn {
    margin: 1rem 0 0 0;
    width: 100%;
    background-color: #f9000c !important;
    color: white;
    border: 2px solid #f9000c;
    padding: 1.40rem 0;
    font-size: larger;
    box-shadow: 0px 0px 5px #000, 0px 0px 5px #858585;
    border: 1px solid #f9000c;
  }

  .contact-btn:hover {
    background-color: transparent !important;
    color: white;
    transition: .4s;
    
  }
  
  .line {
    position: relative;
    top: -0.8rem;
    width: 0;
    height: 0.125rem;
    background:white;
    display: inline-block;
  }
  
  input:focus+.line, textarea:focus+.line {
    width: 100%;
    transition: width 0.5s ease-in-out;
  }
  
  input.form-control {
    margin: -0.3rem 0;
  }
  
  textarea.form-control {
    margin: -0.3rem 0;
  }
  
  .error-message {
    color: white;
    background: #f9000c;
    text-align: left;
  }
  
  .sucess-message {
    font-weight: 600;
    font-style: oblique;
    font-size: 1.40rem;
    color: white;
  }
  
  /*---------------- MEDIA -----------------------*/
  @media(max-width: 768px) {
    .contacts {
      overflow-x: hidden;
    }

    .contacts .container {
      padding: 0 1rem;
    }
  
    .contacts p {
      padding: 0 0.4rem;
    }
  }

  @media (max-width: 767px) {
    .header-information {
     margin-bottom: 3rem;
    }
  }
