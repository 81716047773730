.profile-img {
    width: 16rem;
    height: 30rem;
    padding: .3rem;
    border: 2px solid #f9000c;
}


.about-me-section {
    background: black;
    width: 100%;
    margin-top: 2rem;
}

.about-text {
    margin-top: 2rem;
    color: white;
    font-weight: 500;
}

.photo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;   
}


.about-heading{
    color: white;
    text-transform: uppercase;
    margin-top: 30px;
}

@media(max-width: 468) {
    .profile-img {
        width: 10rem;
    }
}

@media(max-width: 991px) {
    .about-text {
        text-align: center;
    }
}

